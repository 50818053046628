import React from "react"

import { Link, graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

import "./hero.css"

const Banner = () => {
  const { heroImage } = useStaticQuery(
    graphql`
      {
        heroImage: file(
          relativePath: { eq: "new-images/workato-solutions-banner.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              layout: CONSTRAINED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  const heroImageData = getImage(heroImage)

  // Use like this:
  const bgImage = convertToBgImage(heroImageData)

  return (
    <>
      <BackgroundImage
        Tag="div"
        {...bgImage}
        // fluid={imageData}
        className="hero-banner hero-whitefade"
        preserveStackingContext
        style={{ backgroundPosition: "center" }}
      >
        <div className="container">
          <div className="row pb-3">
            <div className="col-lg-6 mr-auto">
              <div className="hero-procurement pb-xs-0">
                <h5 className="text-light-blue text-uppercase font-weight-bold mt-0 mb-4">
                  WORKATO SOLUTIONS
                </h5>
                <h1 className="page-title font-weight-medium mt-0 mb-4">
                  Cloud-Based Integration for Business-Wide Automation
                </h1>
                <p>
                  Connect workflows, processes, and applications with Workato’s
                  drag and drop interface. (No-Code Required.)
                </p>
                <div className="mt-4">
                  <Link
                    aria-label="link"
                    className="font-weight-medium"
                    to="/contact/"
                  >
                    Speak to a Technology Consultant
                    <i className="ml-2 item-arrow" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 p-sm-5"
              style={{ position: "relative" }}
            ></div>
          </div>
        </div>
      </BackgroundImage>
    </>
  )
}

export default Banner
